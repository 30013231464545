export default {
  title: "HidSim",
  description:
    "Ricevi SMS online con numeri di telefono virtuali e registrati in modo anonimo in qualsiasi servizio",
  og: {
    title: "Servizio di verifica SMS",
    description:
      "Ricevi SMS online con numeri di telefono virtuali e registrati in modo anonimo in qualsiasi servizio",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Acquista numeri virtuali per {{name}}",
  },
  login: "Accedi",
  register: "Registrati",
  profile: "Profilo",
  balance: "Saldo",
  topUp: "Ricarica",
  language: "Lingua",
  currency: "Valuta",
  support: "Supporto",
  downloadIosApp: "Scarica l'app iOS",
  downloadAndroidApp: "Scarica l'app Android",
  receipts: "Ricevute",
  invoices: "Fatture",
  logout: "Esci",
  buyVirtualNumbersFor: "Acquista numeri virtuali per {{name}}",
  connecting: "Connessione",
  connected: "Connesso",
  retry: "Riprova",
  credit: "Credito",
  credits: "Crediti",
  services: "Servizi",
  operations: "Operazioni",
  pending: "In sospeso",
  refunded: "Rimborsato",
  completed: "Completato",
  searchServices: "Cerca servizi",
  searchCountryFor: "Cerca paese per {{name}}",
  default: "Predefinito",
  priceHighToLow: "Prezzo alto a basso",
  priceLowToHigh: "Prezzo basso ad alto",
  nothingIsHereYet: "Non c'è ancora nulla",
  errors: {
    "emailAddressAlreadyTaken": "Indirizzo email già utilizzato",
    "number.base": "Inserisci un numero",
    "number.empty": "Questo campo non può essere vuoto",
    "any.required": "Questo campo è obbligatorio",
    "any.only": "Il valore inserito non è consentito",
    "string.base": "Compila il campo",
    "string.empty": "Questo campo non può essere vuoto",
    "0": "Nessuna connessione internet",
    "401": "Non autorizzato",
    "401_message":
      "Non sei autorizzato ad accedere a questa sezione, contatta l'amministratore del sito",
    "500": "Errore di connessione",
    "500_message":
      "Si è verificato un problema di connessione a Internet, riprova",
    "404": "Non trovato",
    "404_message":
      "Non siamo riusciti a trovare quello che cercavi, sei perso? Fai clic sul pulsante sottostante per tornare alla pagina iniziale",
    "409": "Token scaduto",
    "409_message":
      "Il tuo token recente è scaduto, ricarica la pagina e procedi con la transazione",
    "429": "Troppe richieste",
    "429_message":
      "Hai superato il numero consentito di richieste, riprova",
  },
  navbar: {
    services: "Servizi",
    profile: "Profilo",
    myNumbers: "I miei numeri",
  },
  operation: {
    creating: "Creazione",
    pending: "In sospeso",
    completed: "Completato",
    no_number_refunded: "Rimborsato a causa di nessun numero",
    system_refunded: "Rimborsato automaticamente dal sistema",
    user_refunded: "Rimborsato da te",
  },
  auth: {
    login: {
      "mobile.number": "Numero di cellulare",
      password: "Password",
      "email.address": "Indirizzo email",
      invalid: "Email o password non valide",
    },
    register: {
      "mobile.number": "Numero di cellulare",
      "email.address": "Indirizzo email",
      password: {
        hash: "Password",
        confirmation: "Conferma password",
      },
      profile: {
        name: {
          first: "Nome",
          last: "Cognome",
        },
      },
    },
    haveAccountLogin: "Hai un account? Accedi",
    notHaveAccountRegister: "Non hai un account? Registrati",
    forgot: "Password dimenticata",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Numero ordine",
      refundPolicy:
        "Entro 20 minuti se il numero non riceve SMS, il costo verrà restituito al saldo.",
      notEnoughCredits:
        "Attualmente non hai abbastanza crediti. Hai bisogno di {{amount}} crediti in più per utilizzare questo numero.",
      orderFor: "<0>Ordine per</0><1>{{amount}}</1><2>Credito</2>",
      pleaseWait: "Attendere prego",
      addCredits: "Aggiungi crediti",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Annulla ordine",
      cancel: "Annulla",
      pleaseWait: "Attendere prego",
      dismiss: "Annulla",
    },
  },
};
