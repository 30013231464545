export default {
  title: "HidSim",
  description: "使用虚拟电话号码在线接收短信并在任何服务中匿名注册",
  og: {
    title: "短信验证服务",
    description: "使用虚拟电话号码在线接收短信并在任何服务中匿名注册",
    site_name: "HidSim",
    buyVirtualNumbersFor: "购买{{name}}的虚拟号码",
  },
  login: "登录",
  register: "注册",
  profile: "个人资料",
  balance: "余额",
  topUp: "充值",
  language: "语言",
  currency: "货币",
  support: "支持",
  downloadIosApp: "下载iOS应用",
  downloadAndroidApp: "下载Android应用",
  receipts: "收据",
  invoices: "发票",
  logout: "登出",
  buyVirtualNumbersFor: "购买{{name}}的虚拟号码",
  connecting: "连接中",
  connected: "已连接",
  retry: "重试",
  credit: "信用",
  credits: "信用",
  services: "服务",
  operations: "操作",
  pending: "待处理",
  refunded: "已退款",
  completed: "已完成",
  searchServices: "搜索服务",
  searchCountryFor: "搜索{{name}}的国家",
  default: "默认",
  priceHighToLow: "价格从高到低",
  priceLowToHigh: "价格从低到高",
  nothingIsHereYet: "暂无内容",
  errors: {
    "emailAddressAlreadyTaken": "电子邮件地址已被使用",
    "number.base": "请输入一个数字",
    "number.empty": "该字段不能为空",
    "any.required": "该字段为必填项",
    "any.only": "输入的值不允许",
    "string.base": "请填写该字段",
    "string.empty": "该字段不能为空",
    "0": "无网络连接",
    "401": "未经授权",
    "401_message": "您无权访问此部分，请联系网站管理员",
    "500": "连接错误",
    "500_message": "连接到互联网时出现问题，请重试",
    "404": "未找到",
    "404_message": "我们找不到您要找的内容，您迷路了吗？点击下面的按钮返回首页",
    "409": "令牌已过期",
    "409_message": "您的最近令牌已过期，请重新加载页面并继续交易",
    "429": "请求过多",
    "429_message": "您已超过允许的请求数量，请重试",
  },
  navbar: {
    services: "服务",
    profile: "个人资料",
    myNumbers: "我的号码",
  },
  operation: {
    creating: "创建中",
    pending: "待处理",
    completed: "已完成",
    no_number_refunded: "由于没有号码而退款",
    system_refunded: "系统自动退款",
    user_refunded: "您退款",
  },
  auth: {
    login: {
      "mobile.number": "手机号码",
      password: "密码",
      "email.address": "电子邮件地址",
      invalid: "无效的电子邮件或密码",
    },
    register: {
      "mobile.number": "手机号码",
      "email.address": "电子邮件地址",
      password: {
        hash: "密码",
        confirmation: "确认密码",
      },
      profile: {
        name: {
          first: "名字",
          last: "姓氏",
        },
      },
    },
    haveAccountLogin: "已有账号？登录",
    notHaveAccountRegister: "没有账号？注册",
    forgot: "忘记密码",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "订单号",
      refundPolicy: "如果号码在20分钟内未收到短信，则费用将退还到余额。",
      notEnoughCredits: "您当前没有足够的信用。您需要再充值{{amount}}个信用才能使用该号码。",
      orderFor: "<0>订单</0><1>{{amount}}</1><2>信用</2>",
      pleaseWait: "请稍候",
      addCredits: "添加信用",
    },
    cancelOrderConfirmation: {
      cancelOrder: "取消订单",
      cancel: "取消",
      pleaseWait: "请稍候",
      dismiss: "关闭",
    },
  },
};
