export default {
  title: "HidSim",
  description:
    "Nhận tin nhắn SMS trực tuyến với số điện thoại ảo và đăng ký một cách ẩn danh trong bất kỳ dịch vụ nào",
  og: {
    title: "Dịch vụ xác minh SMS",
    description:
      "Nhận tin nhắn SMS trực tuyến với số điện thoại ảo và đăng ký một cách ẩn danh trong bất kỳ dịch vụ nào",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Mua số điện thoại ảo cho {{name}}",
  },
  login: "Đăng nhập",
  register: "Đăng ký",
  profile: "Hồ sơ",
  balance: "Số dư",
  topUp: "Nạp tiền",
  language: "Ngôn ngữ",
  currency: "Tiền tệ",
  support: "Hỗ trợ",
  downloadIosApp: "Tải ứng dụng iOS",
  downloadAndroidApp: "Tải ứng dụng Android",
  receipts: "Hóa đơn",
  invoices: "Hóa đơn",
  logout: "Đăng xuất",
  buyVirtualNumbersFor: "Mua số điện thoại ảo cho {{name}}",
  connecting: "Đang kết nối",
  connected: "Đã kết nối",
  retry: "Thử lại",
  credit: "Tín dụng",
  credits: "Tín dụng",
  services: "Dịch vụ",
  operations: "Hoạt động",
  pending: "Đang chờ",
  refunded: "Đã hoàn tiền",
  completed: "Hoàn thành",
  searchServices: "Tìm kiếm dịch vụ",
  searchCountryFor: "Tìm kiếm quốc gia cho {{name}}",
  default: "Mặc định",
  priceHighToLow: "Giá cao đến thấp",
  priceLowToHigh: "Giá thấp đến cao",
  nothingIsHereYet: "Chưa có gì ở đây",
  errors: {
    "emailAddressAlreadyTaken": "Địa chỉ email đã được sử dụng",
    "number.base": "Vui lòng nhập một số",
    "number.empty": "Trường này không được để trống",
    "any.required": "Trường này là bắt buộc",
    "any.only": "Giá trị đã nhập không được phép",
    "string.base": "Vui lòng điền vào trường này",
    "string.empty": "Trường này không được để trống",
    "0": "Không có kết nối internet",
    "401": "Không được phép",
    "401_message":
      "Bạn không được phép truy cập vào phần này, vui lòng liên hệ với quản trị viên trang web",
    "500": "Lỗi kết nối",
    "500_message":
      "Có vấn đề khi kết nối với internet, vui lòng thử lại",
    "404": "Không tìm thấy",
    "404_message":
      "Chúng tôi không thể tìm thấy những gì bạn đang tìm kiếm, bạn có bị lạc không? Nhấp vào nút bên dưới để về trang chủ",
    "409": "Mã thông báo đã hết hạn",
    "409_message":
      "Mã thông báo gần đây của bạn đã hết hạn, vui lòng tải lại trang và tiếp tục giao dịch",
    "429": "Quá nhiều yêu cầu",
    "429_message":
      "Bạn đã vượt quá số lượng yêu cầu cho phép, vui lòng thử lại",
  },
  navbar: {
    services: "Dịch vụ",
    profile: "Hồ sơ",
    myNumbers: "Số của tôi",
  },
  operation: {
    creating: "Đang tạo",
    pending: "Đang chờ",
    completed: "Hoàn thành",
    no_number_refunded: "Hoàn tiền do không có số",
    system_refunded: "Hệ thống hoàn tiền tự động",
    user_refunded: "Hoàn tiền bởi bạn",
  },
  auth: {
    login: {
      "mobile.number": "Số điện thoại di động",
      password: "Mật khẩu",
      "email.address": "Địa chỉ email",
      invalid: "Email hoặc mật khẩu không hợp lệ",
    },
    register: {
      "mobile.number": "Số điện thoại di động",
      "email.address": "Địa chỉ email",
      password: {
        hash: "Mật khẩu",
        confirmation: "Xác nhận mật khẩu",
      },
      profile: {
        name: {
          first: "Tên",
          last: "Họ",
        },
      },
    },
    haveAccountLogin: "Đã có tài khoản? Đăng nhập",
    notHaveAccountRegister: "Chưa có tài khoản? Đăng ký",
    forgot: "Quên mật khẩu",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Số đơn hàng",
      refundPolicy:
        "Trong vòng 20 phút nếu số không nhận được tin nhắn SMS, số tiền sẽ được hoàn lại vào số dư.",
      notEnoughCredits:
        "Hiện tại bạn không có đủ tín dụng. Bạn cần thêm {{amount}} tín dụng để sử dụng số này.",
      orderFor: "<0>Đặt hàng cho</0><1>{{amount}}</1><2>Tín dụng</2>",
      pleaseWait: "Vui lòng đợi",
      addCredits: "Thêm tín dụng",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Hủy đơn hàng",
      cancel: "Hủy",
      pleaseWait: "Vui lòng đợi",
      dismiss: "Bỏ qua",
    },
  },
};
