export default {
  title: "HidSim",
  description:
    "استقبال الرسائل القصيرة عبر الإنترنت باستخدام أرقام هاتف افتراضية والتسجيل بشكل مجهول في أي خدمة",
  og: {
    title: "خدمة التحقق من الرسائل القصيرة",
    description:
      "استقبال الرسائل القصيرة عبر الإنترنت باستخدام أرقام هاتف افتراضية والتسجيل بشكل مجهول في أي خدمة",
    site_name: "HidSim",
    buyVirtualNumbersFor: "شراء أرقام افتراضية لـ {{name}}",
  },
  login: "تسجيل الدخول",
  register: "التسجيل",
  profile: "الملف الشخصي",
  balance: "الرصيد",
  topUp: "شحن الرصيد",
  language: "اللغة",
  currency: "العملة",
  support: "الدعم",
  downloadIosApp: "تحميل تطبيق iOS",
  downloadAndroidApp: "تحميل تطبيق Android",
  receipts: "الفواتير",
  invoices: "الفواتير",
  logout: "تسجيل الخروج",
  buyVirtualNumbersFor: "شراء أرقام افتراضية لـ {{name}}",
  connecting: "جارٍ الاتصال",
  connected: "متصل",
  retry: "إعادة المحاولة",
  credit: "رصيد",
  credits: "الرصيد",
  services: "الخدمات",
  operations: "العمليات",
  pending: "قيد الانتظار",
  refunded: "تم استرداده",
  completed: "مكتمل",
  searchServices: "البحث عن الخدمات",
  searchCountryFor: "البحث عن البلد لـ {{name}}",
  default: "الافتراضي",
  priceHighToLow: "السعر من الأعلى إلى الأدنى",
  priceLowToHigh: "السعر من الأدنى إلى الأعلى",
  nothingIsHereYet: "لا يوجد شيء هنا بعد",
  errors: {
    "emailAddressAlreadyTaken": "عنوان البريد الإلكتروني مستخدم بالفعل",
    "number.base": "يرجى إدخال رقم",
    "number.empty": "لا يمكن ترك هذا الحقل فارغًا",
    "any.required": "هذا الحقل مطلوب",
    "any.only": "القيمة المدخلة غير مسموح بها",
    "string.base": "يرجى ملء الحقل",
    "string.empty": "لا يمكن ترك هذا الحقل فارغًا",
    "0": "لا يوجد اتصال بالإنترنت",
    "401": "غير مصرح به",
    "401_message":
      "غير مصرح لك بالوصول إلى هذا القسم، يرجى الاتصال بمسؤول الموقع",
    "500": "خطأ في الاتصال",
    "500_message":
      "حدثت مشكلة في الاتصال بالإنترنت، يرجى المحاولة مرة أخرى",
    "404": "غير موجود",
    "404_message":
      "لم نتمكن من العثور على ما تبحث عنه، هل أنت ضائع؟ انقر على الزر أدناه للعودة إلى الصفحة الرئيسية",
    "409": "الرمز المميز منتهي الصلاحية",
    "409_message":
      "انتهت صلاحية الرمز المميز الأخير الخاص بك، يرجى إعادة تحميل الصفحة والمتابعة مع العملية",
    "429": "الكثير من الطلبات",
    "429_message":
      "لقد تجاوزت الحد المسموح به لعدد الطلبات، يرجى المحاولة مرة أخرى",
  },
  navbar: {
    services: "الخدمات",
    profile: "الملف الشخصي",
    myNumbers: "أرقامي",
  },
  operation: {
    creating: "جارٍ الإنشاء",
    pending: "قيد الانتظار",
    completed: "مكتمل",
    no_number_refunded: "تم استرداده بسبب عدم وجود رقم",
    system_refunded: "تم استرداده بشكل تلقائي من النظام",
    user_refunded: "تم استرداده بواسطتك",
  },
  auth: {
    login: {
      "mobile.number": "رقم الجوال",
      password: "كلمة المرور",
      "email.address": "عنوان البريد الإلكتروني",
      invalid: "البريد الإلكتروني أو كلمة المرور غير صحيحة",
    },
    register: {
      "mobile.number": "رقم الجوال",
      "email.address": "عنوان البريد الإلكتروني",
      password: {
        hash: "كلمة المرور",
        confirmation: "تأكيد كلمة المرور",
      },
      profile: {
        name: {
          first: "الاسم الأول",
          last: "الاسم الأخير",
        },
      },
    },
    haveAccountLogin: "هل لديك حساب؟ تسجيل الدخول",
    notHaveAccountRegister: "ليس لديك حساب؟ التسجيل",
    forgot: "نسيت كلمة المرور",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "رقم الطلب",
      refundPolicy:
        "في غضون 20 دقيقة إذا لم يتلق الرقم رسالة نصية، سيتم استرداد التكلفة إلى الرصيد.",
      notEnoughCredits:
        "ليس لديك حاليًا رصيد كافٍ. تحتاج إلى {{amount}} رصيد إضافي لاستخدام هذا الرقم.",
      orderFor: "<0>طلب لـ</0><1>{{amount}}</1><2>رصيد</2>",
      pleaseWait: "يرجى الانتظار",
      addCredits: "إضافة رصيد",
    },
    cancelOrderConfirmation:{
      cancelOrder: "إلغاء الطلب",
      cancel: "إلغاء",
      pleaseWait: "يرجى الانتظار",
      dismiss: "تجاهل",
    },
  },
};
