export default {
  title: "HidSim",
  description:
    "Sanal Telefon Numaralarıyla SMS Alın ve herhangi bir hizmete anonim olarak kaydolun",
  og: {
    title: "SMS Doğrulama Servisi",
    description:
      "Sanal Telefon Numaralarıyla SMS Alın ve herhangi bir hizmete anonim olarak kaydolun",
    site_name: "HidSim",
    buyVirtualNumbersFor: "{{name}} için Sanal Numara Satın Al",
  },
  login: "Giriş",
  register: "Kayıt Ol",
  profile: "Profil",
  balance: "Bakiye",
  topUp: "Yükleme Yap",
  language: "Dil",
  currency: "Para Birimi",
  support: "Destek",
  downloadIosApp: "iOS Uygulamasını İndir",
  downloadAndroidApp: "Android Uygulamasını İndir",
  receipts: "Fişler",
  invoices: "Faturalar",
  logout: "Çıkış Yap",
  buyVirtualNumbersFor: "{{name}} için Sanal Numara Satın Al",
  connecting: "Bağlanıyor",
  connected: "Bağlandı",
  retry: "Tekrar Dene",
  credit: "Kredi",
  credits: "Krediler",
  services: "Hizmetler",
  operations: "İşlemler",
  pending: "Beklemede",
  refunded: "İade Edildi",
  completed: "Tamamlandı",
  searchServices: "Hizmetleri Ara",
  searchCountryFor: "{{name}} için Ülke Ara",
  default: "Varsayılan",
  priceHighToLow: "Fiyat Yüksekten Düşüğe",
  priceLowToHigh: "Fiyat Düşükten Yükseğe",
  nothingIsHereYet: "Henüz hiçbir şey yok",
  errors: {
    "emailAddressAlreadyTaken": "E-posta adresi zaten alınmış",
    "number.base": "Lütfen bir sayı girin",
    "number.empty": "Bu alan boş bırakılamaz",
    "any.required": "Bu alan gereklidir",
    "any.only": "Girilen değer izin verilmiyor",
    "string.base": "Lütfen alanı doldurun",
    "string.empty": "Bu alan boş bırakılamaz",
    "0": "İnternet bağlantısı yok",
    "401": "Yetkisiz",
    "401_message":
      "Bu bölüme erişim izniniz yok, lütfen site yöneticisiyle iletişime geçin",
    "500": "Bağlantı hatası",
    "500_message":
      "İnternete bağlanırken bir sorun oluştu, lütfen tekrar deneyin",
    "404": "Bulunamadı",
    "404_message":
      "Aradığınızı bulamadık, kayboldunuz mu? Ana sayfaya gitmek için aşağıdaki düğmeye tıklayın",
    "409": "Süresi dolmuş token",
    "409_message":
      "Geçerli token süreniz doldu, lütfen sayfayı yeniden yükleyin ve işlemi tamamlayın",
    "429": "Çok fazla istek",
    "429_message":
      "İzin verilen istek sayısını aştınız, lütfen tekrar deneyin",
  },
  navbar: {
    services: "Hizmetler",
    profile: "Profil",
    myNumbers: "Numaralarım",
  },
  operation: {
    creating: "Oluşturuluyor",
    pending: "Beklemede",
    completed: "Tamamlandı",
    no_number_refunded: "Numara olmadığı için iade edildi",
    system_refunded: "Sistem tarafından otomatik olarak iade edildi",
    user_refunded: "Siz tarafından iade edildi",
  },
  auth: {
    login: {
      "mobile.number": "Mobil Numara",
      password: "Şifre",
      "email.address": "E-posta Adresi",
      invalid: "Geçersiz e-posta veya şifre",
    },
    register: {
      "mobile.number": "Mobil Numara",
      "email.address": "E-posta Adresi",
      password: {
        hash: "Şifre",
        confirmation: "Şifre Onayı",
      },
      profile: {
        name: {
          first: "Ad",
          last: "Soyad",
        },
      },
    },
    haveAccountLogin: "Hesabınız var mı? Giriş yapın",
    notHaveAccountRegister: "Hesabınız yok mu? Kayıt olun",
    forgot: "Şifremi unuttum",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Sipariş Numarası",
      refundPolicy:
        "Numara SMS almadığı takdirde 20 dakika içinde maliyet bakiyeye geri dönecektir.",
      notEnoughCredits:
        "Şu anda yeterli krediniz bulunmamaktadır. Bu numarayı kullanmak için {{amount}} daha fazla krediye ihtiyacınız var.",
      orderFor: "<0>Sipariş için</0><1>{{amount}}</1><2>Kredi</2>",
      pleaseWait: "Lütfen bekleyin",
      addCredits: "Kredi Ekle",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Siparişi İptal Et",
      cancel: "İptal Et",
      pleaseWait: "Lütfen bekleyin",
      dismiss: "Kapat",
    },
  },
};
