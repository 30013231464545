export default {
  title: "HidSim",
  description:
    "가상 전화 번호로 온라인에서 SMS를 받고 익명으로 모든 서비스에 등록하세요",
  og: {
    title: "SMS 인증 서비스",
    description:
      "가상 전화 번호로 온라인에서 SMS를 받고 익명으로 모든 서비스에 등록하세요",
    site_name: "HidSim",
    buyVirtualNumbersFor: "{{name}}을(를) 위한 가상 번호 구매",
  },
  login: "로그인",
  register: "회원가입",
  profile: "프로필",
  balance: "잔액",
  topUp: "충전",
  language: "언어",
  currency: "통화",
  support: "지원",
  downloadIosApp: "iOS 앱 다운로드",
  downloadAndroidApp: "Android 앱 다운로드",
  receipts: "영수증",
  invoices: "송장",
  logout: "로그아웃",
  buyVirtualNumbersFor: "{{name}}을(를) 위한 가상 번호 구매",
  connecting: "연결 중",
  connected: "연결됨",
  retry: "재시도",
  credit: "크레딧",
  credits: "크레딧",
  services: "서비스",
  operations: "작업",
  pending: "대기 중",
  refunded: "환불됨",
  completed: "완료됨",
  searchServices: "서비스 검색",
  searchCountryFor: "{{name}}을(를) 위한 국가 검색",
  default: "기본",
  priceHighToLow: "가격 높은 순",
  priceLowToHigh: "가격 낮은 순",
  nothingIsHereYet: "아직 아무것도 없습니다",
  errors: {
    "emailAddressAlreadyTaken": "이미 사용 중인 이메일 주소입니다",
    "number.base": "숫자를 입력해주세요",
    "number.empty": "이 필드는 비워둘 수 없습니다",
    "any.required": "이 필드는 필수입니다",
    "any.only": "입력한 값은 허용되지 않습니다",
    "string.base": "이 필드를 채워주세요",
    "string.empty": "이 필드는 비워둘 수 없습니다",
    "0": "인터넷 연결 없음",
    "401": "인증되지 않음",
    "401_message":
      "이 섹션에 액세스할 권한이 없습니다. 사이트 관리자에게 문의하세요",
    "500": "연결 오류",
    "500_message":
      "인터넷에 연결하는 중에 문제가 발생했습니다. 다시 시도해주세요",
    "404": "찾을 수 없음",
    "404_message":
      "찾고 있는 내용을 찾을 수 없습니다. 길을 잃으셨나요? 아래 버튼을 클릭하여 홈으로 이동하세요",
    "409": "만료된 토큰",
    "409_message":
      "최근 토큰이 만료되었습니다. 페이지를 새로 고침하고 거래를 진행하세요",
    "429": "요청 횟수 초과",
    "429_message":
      "허용된 요청 횟수를 초과했습니다. 다시 시도해주세요",
  },
  navbar: {
    services: "서비스",
    profile: "프로필",
    myNumbers: "내 번호",
  },
  operation: {
    creating: "생성 중",
    pending: "대기 중",
    completed: "완료됨",
    no_number_refunded: "번호 없음으로 인한 환불",
    system_refunded: "시스템에 의한 자동 환불",
    user_refunded: "사용자에 의한 환불",
  },
  auth: {
    login: {
      "mobile.number": "휴대폰 번호",
      password: "비밀번호",
      "email.address": "이메일 주소",
      invalid: "유효하지 않은 이메일 또는 비밀번호",
    },
    register: {
      "mobile.number": "휴대폰 번호",
      "email.address": "이메일 주소",
      password: {
        hash: "비밀번호",
        confirmation: "비밀번호 확인",
      },
      profile: {
        name: {
          first: "이름",
          last: "성",
        },
      },
    },
    haveAccountLogin: "계정이 있으신가요? 로그인",
    notHaveAccountRegister: "계정이 없으신가요? 회원가입",
    forgot: "비밀번호를 잊으셨나요",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "주문 번호",
      refundPolicy:
        "20분 이내에 SMS를 받지 못한 경우, 비용이 잔액으로 반환됩니다.",
      notEnoughCredits:
        "현재 크레딧이 충분하지 않습니다. 이 번호를 사용하려면 {{amount}} 크레딧이 더 필요합니다.",
      orderFor: "<0>주문 대상</0><1>{{amount}}</1><2>크레딧</2>",
      pleaseWait: "잠시 기다려주세요",
      addCredits: "크레딧 추가",
    },
    cancelOrderConfirmation:{
      cancelOrder: "주문 취소",
      cancel: "취소",
      pleaseWait: "잠시 기다려주세요",
      dismiss: "닫기",
    },
  },
};
