export default {
  title: "HidSim",
  description:
    "Recevez des SMS en ligne avec des numéros de téléphone virtuels et inscrivez-vous anonymement sur n'importe quel service",
  og: {
    title: "Service de vérification de SMS",
    description:
      "Recevez des SMS en ligne avec des numéros de téléphone virtuels et inscrivez-vous anonymement sur n'importe quel service",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Acheter des numéros virtuels pour {{name}}",
  },
  login: "Connexion",
  register: "S'inscrire",
  profile: "Profil",
  balance: "Solde",
  topUp: "Recharger",
  language: "Langue",
  currency: "Devise",
  support: "Support",
  downloadIosApp: "Télécharger l'application iOS",
  downloadAndroidApp: "Télécharger l'application Android",
  receipts: "Reçus",
  invoices: "Factures",
  logout: "Déconnexion",
  buyVirtualNumbersFor: "Acheter des numéros virtuels pour {{name}}",
  connecting: "Connexion en cours",
  connected: "Connecté",
  retry: "Réessayer",
  credit: "Crédit",
  credits: "Crédits",
  services: "Services",
  operations: "Opérations",
  pending: "En attente",
  refunded: "Remboursé",
  completed: "Terminé",
  searchServices: "Rechercher des services",
  searchCountryFor: "Rechercher un pays pour {{name}}",
  default: "Par défaut",
  priceHighToLow: "Prix élevé à bas",
  priceLowToHigh: "Prix bas à élevé",
  nothingIsHereYet: "Rien n'est encore ici",
  errors: {
    "emailAddressAlreadyTaken": "Adresse e-mail déjà utilisée",
    "number.base": "Veuillez entrer un nombre",
    "number.empty": "Ce champ ne peut pas être vide",
    "any.required": "Ce champ est obligatoire",
    "any.only": "La valeur saisie n'est pas autorisée",
    "string.base": "Veuillez remplir le champ",
    "string.empty": "Ce champ ne peut pas être vide",
    "0": "Pas de connexion internet",
    "401": "Non autorisé",
    "401_message":
      "Vous n'êtes pas autorisé à accéder à cette section, veuillez contacter l'administrateur du site",
    "500": "Erreur de connexion",
    "500_message":
      "Un problème est survenu lors de la connexion à Internet, veuillez réessayer",
    "404": "Non trouvé",
    "404_message":
      "Nous n'avons pas pu trouver ce que vous cherchiez, êtes-vous perdu ? Cliquez sur le bouton ci-dessous pour revenir à la page d'accueil",
    "409": "Jeton expiré",
    "409_message":
      "Votre jeton récent a expiré, veuillez recharger la page et poursuivre la transaction",
    "429": "Trop de requêtes",
    "429_message":
      "Vous avez dépassé le nombre de requêtes autorisées, veuillez réessayer",
  },
  navbar: {
    services: "Services",
    profile: "Profil",
    myNumbers: "Mes numéros",
  },
  operation: {
    creating: "Création",
    pending: "En attente",
    completed: "Terminé",
    no_number_refunded: "Remboursé en raison de l'absence de numéro",
    system_refunded: "Remboursé automatiquement par le système",
    user_refunded: "Remboursé par vous",
  },
  auth: {
    login: {
      "mobile.number": "Numéro de téléphone mobile",
      password: "Mot de passe",
      "email.address": "Adresse e-mail",
      invalid: "E-mail ou mot de passe invalide",
    },
    register: {
      "mobile.number": "Numéro de téléphone mobile",
      "email.address": "Adresse e-mail",
      password: {
        hash: "Mot de passe",
        confirmation: "Confirmation du mot de passe",
      },
      profile: {
        name: {
          first: "Prénom",
          last: "Nom de famille",
        },
      },
    },
    haveAccountLogin: "Vous avez un compte ? Connectez-vous",
    notHaveAccountRegister: "Vous n'avez pas de compte ? Inscrivez-vous",
    forgot: "Mot de passe oublié",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Numéro de commande",
      refundPolicy:
        "Dans les 20 minutes, si le numéro ne reçoit pas de SMS, le coût sera remboursé sur le solde.",
      notEnoughCredits:
        "Vous n'avez actuellement pas assez de crédits. Vous avez besoin de {{amount}} crédits supplémentaires pour utiliser ce numéro.",
      orderFor: "<0>Commander pour</0><1>{{amount}}</1><2>Crédit</2>",
      pleaseWait: "Veuillez patienter",
      addCredits: "Ajouter des crédits",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Annuler la commande",
      cancel: "Annuler",
      pleaseWait: "Veuillez patienter",
      dismiss: "Ignorer",
    },
  },
};
