export default {
  title: "HidSim: Buy Virtual Numbers For SMS Verification",
  description:
    "Receive SMS Online With Virtual Phone Numbers and register anonymously in any service",
  og: {
    title: "HidSim: SMS Verification Service",
    description:
      "Receive SMS Online With Virtual Phone Numbers and register anonymously in any service",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Buy Virtual Numbers For {{name}}",
  },
  login: "Login",
  register: "Register",
  profile: "Profile",
  balance: "Balance",
  dangerZone: "Danger Zone",
  deleteAccount: "Delete Account",
  backToHome: "Back to Home",
  topUp: "Top Up",
  language: "Language",
  currency: "Currency",
  currencies: "Currencies",
  settings: "Settings",
  myReceipts: "My Receipts",
  support: "Support",
  chatOnline: "Chat Online",
  downloadIosApp: "Download iOS App",
  downloadAndroidApp: "Download Android App",
  receipts: "Receipts",
  invoices: "Invoices",
  logout: "Logout",
  buyVirtualNumbersFor: "Buy Virtual Numbers For {{name}}",
  disconnected: "Disconnected",
  connecting: "Connecting",
  connected: "Connected",
  retry: "Retry",
  credit: "Credit",
  credits: "Credits",
  coins: "Coins",
  coin_one: "{{count}} Coin",
  coin_other: "{{count}} Coins",
  services: "Services",
  operations: "Operations",
  pending: "Pending",
  refunded: "Refunded",
  completed: "Completed",
  searchServices: "Search Services",
  searchCountryFor: "Search Country For {{name}}",
  default: "Default",
  priceHighToLow: "Price High To Low",
  priceLowToHigh: "Price Low To High",
  qualityHighToLow: "Quality High To Low",
  qualityLowToHigh: "Quality Low To High",
  nothingIsHereYet: "Nothing is here yet",
  okay: "Okay",
  done: "Done",
  myWallet: "My Wallet",
  copied: "Copied",
  copy: "Copy",
  numbersAvailable: "Numbers Available",
  noNumbersAvailable: "No Numbers Available",
  floating: "Floating",
  automatic: "Automatic",
  findingCountry: "Finding Country",
  letHidSimFind: "Let HidSim Find",
  refund: "Refund",
  sessions: "Sessions",
  activeSessions: "Active Sessions",
  myDevices: "My Devices",
  devices: "Devices",
  revoke: "Revoke",
  installAndroidApp: "Install Android App",
  installIosApp: "Install iOS App",
  pagination: {
    previous: "Previous",
    next: "Next",
  },
  readMore: "Read More",
  showMore: "Show More",
  didNotReceiveCode: "Didn't receive the code?",
  providers: {
    p1: "Operator One",
    p2: "Operator Two",
    p3: "Operator Three",
  },
  grades: {
    unknown: "Unknown",
    poor: "Poor",
    moderate: "Moderate",
    standard: "Standard",
  },
  qualityTheBetterTheHigher:
    "Quality: The better the quality of reception on a specific service and number, the higher the quality.",
  theCountOfAvailableNumbersOperatorIndicated:
    "The number of available numbers shown is an estimate provided by the operator. It may not be exact. We recommend trying the service to see if it works. If it doesn't, a refund will be issued.",
  errors: {
    emailAddressAlreadyTaken: "Email address already taken",
    "number.base": "Please enter a number",
    "number.empty": "This field cannot be empty",
    "any.required": "This field is required",
    "any.only": "The entered value is not allowed",
    "string.base": "Please fill in the field",
    "string.empty": "This field cannot be empty",
    "0": "No internet connection",
    "0_message":
      "There was a problem connecting to the internet, please try again",
    "401": "Unauthorized",
    "401_message":
      "You are not authorized to access this section, please contact the site administrator",
    "500": "Connection error",
    "500_message":
      "There was a problem connecting to the internet, please try again",
    "404": "Not found",
    "404_message":
      "We couldn't find what you were looking for, are you lost? Click the button below to go home",
    "409": "Expired token",
    "409_message":
      "Your recent token has expired, please reload the page and proceed with the transaction",
    "429": "Too many requests",
    "429_message": "Too many requests, try again after {{seconds}} seconds",
  },
  faq: "Faq",
  home: {
    title: "HidSim; Privacy-First Communication",
    description:
      "Imagine you want to create accounts for using social networks like Instagram, Twitter, Facebook, and various websites and apps. However, to register and complete the signup process, you need to enter a mobile number and verify it via SMS. Especially when it’s not possible or you don't wish to use your personal number, or you need to create a large number of accounts for personal or business purposes, HidSim’s virtual number panel offers you the capability to receive numbers from over 160 countries around the world, including European and Asian countries, at a very low cost. These numbers can receive SMS messages containing activation codes for creating accounts on any service.",
    benefits: {
      instantDelivery: "Instant Delivery",
      secureAndAnonymous: "Secure & Anonymous",
      onlyPayForReceivedCodes: "Only Pay for Received Codes",
      visaAndCreditCardAccepted: "Visa & Credit Card Accepted",
      cryptoAccepted: "Crypto Accepted",
    },
    faq: {
      q1: "How Can I get it?",
      // Additionally, you can install our app from the App Store or Google Play and purchase credit packages directly within the app
      a1: "You can top up your balance using cryptocurrency. Once topped up, select the service and country you want, then purchase virtual numbers with your credit.",

      q2: "What happens if I don't receive Code?",
      a2: "If you don’t receive a code on your number, there could be multiple reasons, such as network issues or the sending service’s delay. In this case, you can cancel the activation and retry with a new number; the funds will be automatically refunded to your balance. Using a VPN or proxy from the country of the number might help improve delivery success.",

      q3: "How long are the numbers available for me?",
      a3: "When you purchase a number, it is available for one-time use, and you own it for 15 minutes. During this period, enter the number into the desired service. HidSim will monitor for the incoming code and display it in the activations tab inside our app. Once the code is received, the number is no longer active.",

      q4: "Why was my number blocked by a service?",
      a4: "Your number may be blocked due to usage patterns, IP address issues, or being flagged as robotic. To avoid this, use a realistic name and profile picture, engage in genuine conversations, avoid acquiring numbers too quickly, and consider using a VPN that matches the service’s country.",
      q5: "Why can't I receive a code in Telegram services?",
      a5: "For your first login in Telegram, avoid using laptops or tablets. Use a mobile phone to create your account initially. After your first Sign-up is completed, you can move your account to any other devices you prefer.",

      q6: "Can I receive a code for a number more than once?",
      a6: "No, it is not possible to receive a code for the same number more than once. Each virtual number is for one-time use only, and after receiving the code, it becomes inactive.",

      qOther: "Do you have any other questions?",
      aOther:
        'Chat with our excellent customer support team on <0 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">Telegram</0> and get answers within minutes, 24/7. <1 href="https://t.me/HidSimsupport" target="_blank" style="color: #6B96BD;">Contact on Telegram</1>',
    },
  },
  navbar: {
    myAccount: "My Account",
    startNow: "Start Now",
    login: "Login",
    services: "Services",
    profile: "Profile",
    myNumbers: "My Numbers",
  },
  operation: {
    creating: "Creating",
    pending: "Active",
    completed: "Completed",
    no_number_refunded: "Refunded due to no number",
    system_refunded: "System refunded automatically",
    user_refunded: "Refunded by you",
    waitingForMessage: "Waiting for message",
  },
  receipt: {
    purchase: "Purchased Number",
    refund_with_revoke: "Refund with revoke",
    refund_with_fraud_revoke: "Refund with fraud revoke",
    refund_with_user_action: "Refund with user action",
    refund_with_system_action: "Refund with system action",
    system_freeze: "Balance Freezed",
    system_unfreeze: "Balance Released",
    system_fee: "Fee",
  },
  invoice: {
    paid: "Paid",
    paid_over: "Paid Over",
    wrong_amount: "Wrong Amount",
    process: "Process",
    confirm_check: "Confirm Check",
    wrong_amount_waiting: "Wrong Amount Waiting",
    check: "Check",
    fail: "Fail",
    cancel: "Cancel",
    system_fail: "System Fail",
    refund_process: "Refund Process",
    refund_fail: "Refund Fail",
    refund_paid: "Refund Paid",
  },
  auth: {
    login: {
      "mobile.number": "Mobile Number",
      password: "Password",
      "email.address": "Email Address",
      invalid: "Invalid email or password",
    },
    register: {
      "mobile.number": "Mobile Number",
      "email.address": "Email Address",
      password: {
        hash: "Password",
        confirmation: "Password Confirmation",
      },
      profile: {
        name: {
          first: "First Name",
          last: "Last Name",
        },
      },
    },
    alreadyHaveAccount: "Already have an Account?",
    notHaveAccount: "Don't have account yet?",
    forgotYourPassword: "Forgot your password?",
    registerNow: "Register Now",
    loginToHidSim: "Login to HidSim",
    registerToHidSim: "Register to HidSim",
    enterYourCredentials: "Enter your credentials to continue",
    backToHome: "Back to Home",
    byRegisteringYouAgreeToOurTermsOfService: `By signing up, you agree to our <2>Terms of Service</2> and <6>Privacy Policy</6>.`,
  },
  landing: {
    nav: {},
  },
  modals: {
    downloadNativeApplication: {
      howToDownload: "How to Download",
        androidDescription: "We’re thrilled to announce that the Android version of HidSim (complete with in-app purchase support) is now live for a closed test! 🎉 Here’s your exclusive chance to be among the first to experience our virtual SMS verification service and help us perfect the app before its official release. To participate, simply send your Google Play email to our Telegram support",
     iosDescription: "Stay tuned for the iOS version of HidSim, coming soon! In the meantime, you can access our virtual SMS verification service via the web app. For the latest updates on the iOS app, follow us on Telegram. @HidSim",
        downloadAndroidApp: "Download Android App",
      downloadIosApp: "Not available yet for iOS",
    },
    revokeConfirmation: {
      title: "Revoke Confirmation",
      browser: "Browser",
      os: "OS",
      country: "Country",
      ip: "IP",
      discard: "Discard",
      pleaseWait: "Please wait",
      revoke: "Revoke",
    },
    addToHome: {
      title: "Add HidSim to Home Screen",
      ios: {
        one: "1. with <b>Safari Browser</b> Tap on the box with outgoing flash Similar to <img />",
        two: "2. and then tap on the <span key='0'>Add To Home Screen</span>",
        three:
          "3. Please wait a second until icon and information shows up and then tap on the <span>Add</span> button.",
      },
      android: {
        one: "1. Tap on <b>3 dots</b> on the top of the screen next to the address bar in <b>Chrome</b>.",
        two: "2. Tap on the <b>Add To Home Screen</b>.",
        three: "3. Tap on <b>Install</b> Option.",
      },
    },
    init: {
      title: "Welcome to HidSim",
      subtitle: "Select Your Language and Currency to Get Started",
      selectLanguage: "Select Language",
      selectCurrency: "Select Currency",
    },
    charge: {
      title: "Purchase Coins",
      payWithCrypto: "Pay with Crypto",
      payWithCreditCard: "Pay with Credit Card",
      payWithIrLocalGateway: "Pay with Rial",
      mostPopular: "Most Popular",
      otherPackages: "Other Packages",
      submitBtn:
        "<0>Buy </0><1>{{name}}</1><2> for </2><3><0>{{symbol}}</0><1>{{price}}</1></3>",
      notAcceptable:
        "This payment method is not available based on your currency unit, please change your currency unit first.",
      useNativeApplications:
        "For credit and debit card payments, please use our native applications. download links are available in profile page, in case you have any problem with the payment, please contact us.",
    },
    orderConfirmation: {
      orderNumber: "Order Number",
      refundPolicy:
        "Within 20 minutes if the number does not receive SMS, the cost will be returned to the balance.",
      notEnoughCredits:
        "You currently don't have enough credits. You need {{amount}} more credits to use this number.",
      orderFor: "<0>Order For</0><1>{{amount}}</1><2>Credit</2>",
      pleaseWait: "Please wait",
      addCredits: "Add Credits",
    },
    autoOrderConfirmation: {
      refundPolicy: `HidSim will automatically search for the right country with your chosen conditions, during this time it is not necessary to open the application page and you can leave the program and come back again.`,
      noProductFoundForThisRange:
        "No products found for this price, please change your price range.",
      upperLimit: "Upper Limit",
      lowerLimit: "Lower Limit",
      note: "10% fee for automatic orders will be applied",
    },
    cancelOrderConfirmation: {
      refundNumber: "Refund Number",
      refund: "Refund",
      pleaseWait: "Please wait",
      discard: "Discard",
    },
    deleteUserConfirmation: {
      deleteAccount: "Delete Account",
      areYouSure:
        "Are you sure you want to delete your account? this action is irreversible and will delete all your data.",
      delete: "Delete",
      pleaseWait: "Please wait",
      discard: "Discard",
    },
    operationWhy: {
      whyNumberIsRefunded: {
        no_number_refunded:
          "Why the number is refunded due to No Available Number?",
        system_refunded: "Why the number is refunded due to System Action?",
        timeout_system_refunded: "Why the number is refunded?",
        user_refunded: "Refunded by you",
      },
      no_number_refunded:
        "The inventory numbers for each country are provided based on data from telecom operators. However, occasionally, despite having stock, a number may not be activated by these operators for HidSim, resulting in a <No number available> warning. This issue arises due to disruptions with international telecom providers, and is beyond HidSim's control.",
      system_refunded:
        "Due to the system error, the number you purchased could not be activated. The system automatically refunded the amount you paid. you can try again after minutes or hours.",
      timeout_system_refunded:
        "After 15-20 minutes inactivity system automatically refunded the purchase to your wallet.",
      user_refunded: "The purchased Refunded by you.",
    },
  },
};
