export default {
  title: "HidSim",
  description:
    "Recibe SMS en línea con números de teléfono virtuales y regístrate de forma anónima en cualquier servicio",
  og: {
    title: "Servicio de verificación de SMS",
    description:
      "Recibe SMS en línea con números de teléfono virtuales y regístrate de forma anónima en cualquier servicio",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Comprar números virtuales para {{name}}",
  },
  login: "Iniciar sesión",
  register: "Registrarse",
  profile: "Perfil",
  balance: "Saldo",
  topUp: "Recargar",
  language: "Idioma",
  currency: "Moneda",
  support: "Soporte",
  downloadIosApp: "Descargar aplicación para iOS",
  downloadAndroidApp: "Descargar aplicación para Android",
  receipts: "Recibos",
  invoices: "Facturas",
  logout: "Cerrar sesión",
  buyVirtualNumbersFor: "Comprar números virtuales para {{name}}",
  connecting: "Conectando",
  connected: "Conectado",
  retry: "Reintentar",
  credit: "Crédito",
  credits: "Créditos",
  services: "Servicios",
  operations: "Operaciones",
  pending: "Pendiente",
  refunded: "Reembolsado",
  completed: "Completado",
  searchServices: "Buscar servicios",
  searchCountryFor: "Buscar país para {{name}}",
  default: "Predeterminado",
  priceHighToLow: "Precio de mayor a menor",
  priceLowToHigh: "Precio de menor a mayor",
  nothingIsHereYet: "Todavía no hay nada aquí",
  errors: {
    "emailAddressAlreadyTaken": "La dirección de correo electrónico ya está en uso",
    "number.base": "Por favor, introduce un número",
    "number.empty": "Este campo no puede estar vacío",
    "any.required": "Este campo es obligatorio",
    "any.only": "El valor introducido no está permitido",
    "string.base": "Por favor, rellena el campo",
    "string.empty": "Este campo no puede estar vacío",
    "0": "No hay conexión a internet",
    "401": "No autorizado",
    "401_message":
      "No estás autorizado para acceder a esta sección, por favor, contacta al administrador del sitio",
    "500": "Error de conexión",
    "500_message":
      "Hubo un problema al conectar con internet, por favor, inténtalo de nuevo",
    "404": "No encontrado",
    "404_message":
      "No pudimos encontrar lo que estabas buscando, ¿estás perdido? Haz clic en el botón de abajo para ir a la página de inicio",
    "409": "Token expirado",
    "409_message":
      "Tu token reciente ha expirado, por favor, recarga la página y continúa con la transacción",
    "429": "Demasiadas solicitudes",
    "429_message":
      "Has superado el número permitido de solicitudes, por favor, inténtalo de nuevo",
  },
  navbar: {
    services: "Servicios",
    profile: "Perfil",
    myNumbers: "Mis números",
  },
  operation: {
    creating: "Creando",
    pending: "Pendiente",
    completed: "Completado",
    no_number_refunded: "Reembolsado debido a la falta de número",
    system_refunded: "Reembolsado automáticamente por el sistema",
    user_refunded: "Reembolsado por ti",
  },
  auth: {
    login: {
      "mobile.number": "Número de teléfono móvil",
      password: "Contraseña",
      "email.address": "Dirección de correo electrónico",
      invalid: "Correo electrónico o contraseña no válidos",
    },
    register: {
      "mobile.number": "Número de teléfono móvil",
      "email.address": "Dirección de correo electrónico",
      password: {
        hash: "Contraseña",
        confirmation: "Confirmación de contraseña",
      },
      profile: {
        name: {
          first: "Nombre",
          last: "Apellido",
        },
      },
    },
    haveAccountLogin: "¿Tienes una cuenta? Inicia sesión",
    notHaveAccountRegister: "¿No tienes una cuenta? Regístrate",
    forgot: "¿Olvidaste tu contraseña?",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Número de pedido",
      refundPolicy:
        "Si en 20 minutos el número no recibe SMS, el costo será devuelto al saldo.",
      notEnoughCredits:
        "Actualmente no tienes suficientes créditos. Necesitas {{amount}} créditos más para usar este número.",
      orderFor: "<0>Pedido para</0><1>{{amount}}</1><2>Crédito</2>",
      pleaseWait: "Por favor, espera",
      addCredits: "Agregar créditos",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Cancelar pedido",
      cancel: "Cancelar",
      pleaseWait: "Por favor, espera",
      dismiss: "Descartar",
    },
  },
};
