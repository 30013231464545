export default {
  title: "HidSim",
  description:
    "Ta emot SMS online med virtuella telefonnummer och registrera anonymt i vilken tjänst som helst",
  og: {
    title: "SMS-verifikationstjänst",
    description:
      "Ta emot SMS online med virtuella telefonnummer och registrera anonymt i vilken tjänst som helst",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Köp virtuella nummer för {{name}}",
  },
  login: "Logga in",
  register: "Registrera",
  profile: "Profil",
  balance: "Saldo",
  topUp: "Fyll på",
  language: "Språk",
  currency: "Valuta",
  support: "Support",
  downloadIosApp: "Ladda ner iOS-appen",
  downloadAndroidApp: "Ladda ner Android-appen",
  receipts: "Kvitton",
  invoices: "Fakturor",
  logout: "Logga ut",
  buyVirtualNumbersFor: "Köp virtuella nummer för {{name}}",
  connecting: "Ansluter",
  connected: "Ansluten",
  retry: "Försök igen",
  credit: "Kredit",
  credits: "Krediter",
  services: "Tjänster",
  operations: "Operationer",
  pending: "Väntande",
  refunded: "Återbetald",
  completed: "Slutförd",
  searchServices: "Sök tjänster",
  searchCountryFor: "Sök land för {{name}}",
  default: "Standard",
  priceHighToLow: "Pris högt till lågt",
  priceLowToHigh: "Pris lågt till högt",
  nothingIsHereYet: "Ingenting finns här ännu",
  errors: {
    "emailAddressAlreadyTaken": "E-postadressen är redan upptagen",
    "number.base": "Ange ett nummer",
    "number.empty": "Detta fält kan inte vara tomt",
    "any.required": "Detta fält är obligatoriskt",
    "any.only": "Det angivna värdet är inte tillåtet",
    "string.base": "Fyll i fältet",
    "string.empty": "Detta fält kan inte vara tomt",
    "0": "Ingen internetanslutning",
    "401": "Obehörig",
    "401_message":
      "Du har inte behörighet att komma åt den här sektionen, kontakta webbplatsadministratören",
    "500": "Anslutningsfel",
    "500_message":
      "Det uppstod ett problem med att ansluta till internet, försök igen",
    "404": "Hittades inte",
    "404_message":
      "Vi kunde inte hitta det du letade efter, är du vilse? Klicka på knappen nedan för att gå till startsidan",
    "409": "Utgånget token",
    "409_message":
      "Din senaste token har gått ut, ladda om sidan och fortsätt med transaktionen",
    "429": "För många förfrågningar",
    "429_message":
      "Du har överskridit det tillåtna antalet förfrågningar, försök igen",
  },
  navbar: {
    services: "Tjänster",
    profile: "Profil",
    myNumbers: "Mina Nummer",
  },
  operation: {
    creating: "Skapar",
    pending: "Väntande",
    completed: "Slutförd",
    no_number_refunded: "Återbetald på grund av inget nummer",
    system_refunded: "Systemet återbetalade automatiskt",
    user_refunded: "Återbetald av dig",
  },
  auth: {
    login: {
      "mobile.number": "Mobilnummer",
      password: "Lösenord",
      "email.address": "E-postadress",
      invalid: "Ogiltig e-post eller lösenord",
    },
    register: {
      "mobile.number": "Mobilnummer",
      "email.address": "E-postadress",
      password: {
        hash: "Lösenord",
        confirmation: "Lösenordsbekräftelse",
      },
      profile: {
        name: {
          first: "Förnamn",
          last: "Efternamn",
        },
      },
    },
    haveAccountLogin: "Har konto? Logga in",
    notHaveAccountRegister: "Har inget konto? Registrera",
    forgot: "Glömt lösenord",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Ordernummer",
      refundPolicy:
        "Inom 20 minuter om numret inte tar emot SMS kommer kostnaden att återbetalas till saldot.",
      notEnoughCredits:
        "Du har för närvarande inte tillräckligt med krediter. Du behöver {{amount}} fler krediter för att använda detta nummer.",
      orderFor: "<0>Beställ för</0><1>{{amount}}</1><2>Kredit</2>",
      pleaseWait: "Var god vänta",
      addCredits: "Lägg till krediter",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Avbryt beställning",
      cancel: "Avbryt",
      pleaseWait: "Var god vänta",
      dismiss: "Avfärda",
    },
  },
};
