export default {
  title: "HidSim",
  description:
    "वर्चुअल फोन नंबर के साथ ऑनलाइन एसएमएस प्राप्त करें और किसी भी सेवा में गोपनीयता से पंजीकृत हों",
  og: {
    title: "एसएमएस सत्यापन सेवा",
    description:
      "वर्चुअल फोन नंबर के साथ ऑनलाइन एसएमएस प्राप्त करें और किसी भी सेवा में गोपनीयता से पंजीकृत हों",
    site_name: "HidSim",
    buyVirtualNumbersFor: "{{name}} के लिए वर्चुअल नंबर खरीदें",
  },
  login: "लॉगिन",
  register: "रजिस्टर",
  profile: "प्रोफ़ाइल",
  balance: "शेष राशि",
  topUp: "शेष राशि बढ़ाएं",
  language: "भाषा",
  currency: "मुद्रा",
  support: "सहायता",
  downloadIosApp: "iOS ऐप डाउनलोड करें",
  downloadAndroidApp: "Android ऐप डाउनलोड करें",
  receipts: "रसीदें",
  invoices: "चालान",
  logout: "लॉगआउट",
  buyVirtualNumbersFor: "{{name}} के लिए वर्चुअल नंबर खरीदें",
  connecting: "कनेक्ट हो रहा है",
  connected: "कनेक्ट हो गया",
  retry: "पुनः प्रयास करें",
  credit: "क्रेडिट",
  credits: "क्रेडिट",
  services: "सेवाएं",
  operations: "ऑपरेशन",
  pending: "लंबित",
  refunded: "वापसी की गई",
  completed: "पूर्ण",
  searchServices: "सेवाओं की खोज करें",
  searchCountryFor: "{{name}} के लिए देश खोजें",
  default: "डिफ़ॉल्ट",
  priceHighToLow: "मूल्य उच्च से कम",
  priceLowToHigh: "मूल्य कम से उच्च",
  nothingIsHereYet: "यहां अभी तक कुछ नहीं है",
  errors: {
    "emailAddressAlreadyTaken": "ईमेल पता पहले से लिया गया है",
    "number.base": "कृपया एक संख्या दर्ज करें",
    "number.empty": "यह फ़ील्ड खाली नहीं हो सकती",
    "any.required": "यह फ़ील्ड आवश्यक है",
    "any.only": "दर्ज की गई मान अनुमति नहीं है",
    "string.base": "कृपया फ़ील्ड भरें",
    "string.empty": "यह फ़ील्ड खाली नहीं हो सकती",
    "0": "कोई इंटरनेट कनेक्शन नहीं",
    "401": "अनधिकृत",
    "401_message":
      "आपको इस खंड तक पहुँच की अनुमति नहीं है, कृपया साइट प्रशासक से संपर्क करें",
    "500": "कनेक्शन त्रुटि",
    "500_message":
      "इंटरनेट से कनेक्ट करने में समस्या हुई है, कृपया पुनः प्रयास करें",
    "404": "नहीं मिला",
    "404_message":
      "हमें वह नहीं मिला जिसकी आप तलाश कर रहे थे, क्या आप भटक गए हैं? घर पर जाने के लिए नीचे दिए गए बटन पर क्लिक करें",
    "409": "समय सीमा समाप्त",
    "409_message":
      "आपका हाल का टोकन समाप्त हो गया है, कृपया पृष्ठ को रीलोड करें और लेनदेन जारी रखें",
    "429": "बहुत सारे अनुरोध",
    "429_message":
      "आपने अनुमति दी हुई अनुरोधों की संख्या पार कर दी है, कृपया पुनः प्रयास करें",
  },
  navbar: {
    services: "सेवाएं",
    profile: "प्रोफ़ाइल",
    myNumbers: "मेरे नंबर",
  },
  operation: {
    creating: "बनाना",
    pending: "लंबित",
    completed: "पूर्ण",
    no_number_refunded: "कोई नंबर नहीं मिलने के कारण वापसी की गई",
    system_refunded: "सिस्टम द्वारा स्वचालित रूप से वापसी की गई",
    user_refunded: "आपके द्वारा वापसी की गई",
  },
  auth: {
    login: {
      "mobile.number": "मोबाइल नंबर",
      password: "पासवर्ड",
      "email.address": "ईमेल पता",
      invalid: "अमान्य ईमेल या पासवर्ड",
    },
    register: {
      "mobile.number": "मोबाइल नंबर",
      "email.address": "ईमेल पता",
      password: {
        hash: "पासवर्ड",
        confirmation: "पासवर्ड की पुष्टि",
      },
      profile: {
        name: {
          first: "पहला नाम",
          last: "अंतिम नाम",
        },
      },
    },
    haveAccountLogin: "खाता है? लॉगिन करें",
    notHaveAccountRegister: "खाता नहीं है? रजिस्टर करें",
    forgot: "पासवर्ड भूल गए",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "आदेश नंबर",
      refundPolicy:
        "20 मिनट के भीतर यदि नंबर को एसएमएस प्राप्त नहीं होता है, तो राशि शेष में वापस कर दी जाएगी।",
      notEnoughCredits:
        "आपके पास वर्तमान में पर्याप्त क्रेडिट नहीं हैं। आपको इस नंबर का उपयोग करने के लिए {{amount}} और क्रेडिट चाहिए।",
      orderFor: "<0>के लिए आदेश</0><1>{{amount}}</1><2>क्रेडिट</2>",
      pleaseWait: "कृपया प्रतीक्षा करें",
      addCredits: "क्रेडिट जोड़ें",
    },
    cancelOrderConfirmation:{
      cancelOrder: "आदेश रद्द करें",
      cancel: "रद्द करें",
      pleaseWait: "कृपया प्रतीक्षा करें",
      dismiss: "खारिज करें",
    },
  },
};
