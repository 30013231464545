export default {
  title: "HidSim",
  description:
    "Ontvang SMS online met virtuele telefoonnummers en registreer anoniem voor elke service",
  og: {
    title: "SMS Verificatiedienst",
    description:
      "Ontvang SMS online met virtuele telefoonnummers en registreer anoniem voor elke service",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Koop virtuele nummers voor {{name}}",
  },
  login: "Inloggen",
  register: "Registreren",
  profile: "Profiel",
  balance: "Balans",
  topUp: "Opwaarderen",
  language: "Taal",
  currency: "Valuta",
  support: "Ondersteuning",
  downloadIosApp: "Download iOS-app",
  downloadAndroidApp: "Download Android-app",
  receipts: "Ontvangsten",
  invoices: "Facturen",
  logout: "Uitloggen",
  buyVirtualNumbersFor: "Koop virtuele nummers voor {{name}}",
  connecting: "Verbinden",
  connected: "Verbonden",
  retry: "Opnieuw proberen",
  credit: "Krediet",
  credits: "Credits",
  services: "Diensten",
  operations: "Operaties",
  pending: "In behandeling",
  refunded: "Terugbetaald",
  completed: "Voltooid",
  searchServices: "Zoek diensten",
  searchCountryFor: "Zoek land voor {{name}}",
  default: "Standaard",
  priceHighToLow: "Prijs hoog naar laag",
  priceLowToHigh: "Prijs laag naar hoog",
  nothingIsHereYet: "Er is nog niets hier",
  errors: {
    "emailAddressAlreadyTaken": "E-mailadres is al in gebruik",
    "number.base": "Voer een nummer in",
    "number.empty": "Dit veld mag niet leeg zijn",
    "any.required": "Dit veld is verplicht",
    "any.only": "De ingevoerde waarde is niet toegestaan",
    "string.base": "Vul het veld in",
    "string.empty": "Dit veld mag niet leeg zijn",
    "0": "Geen internetverbinding",
    "401": "Ongeautoriseerd",
    "401_message":
      "U bent niet geautoriseerd om toegang te krijgen tot dit gedeelte, neem contact op met de sitebeheerder",
    "500": "Verbindingsfout",
    "500_message":
      "Er is een probleem opgetreden bij het verbinden met internet, probeer het opnieuw",
    "404": "Niet gevonden",
    "404_message":
      "We konden niet vinden waar u naar op zoek was, bent u verdwaald? Klik op de knop hieronder om naar de startpagina te gaan",
    "409": "Verlopen token",
    "409_message":
      "Uw recente token is verlopen, vernieuw de pagina en ga verder met de transactie",
    "429": "Te veel verzoeken",
    "429_message":
      "U heeft het toegestane aantal verzoeken overschreden, probeer het opnieuw",
  },
  navbar: {
    services: "Diensten",
    profile: "Profiel",
    myNumbers: "Mijn Nummers",
  },
  operation: {
    creating: "Aanmaken",
    pending: "In behandeling",
    completed: "Voltooid",
    no_number_refunded: "Terugbetaald vanwege geen nummer",
    system_refunded: "Automatisch terugbetaald door systeem",
    user_refunded: "Terugbetaald door u",
  },
  auth: {
    login: {
      "mobile.number": "Mobiel nummer",
      password: "Wachtwoord",
      "email.address": "E-mailadres",
      invalid: "Ongeldige e-mail of wachtwoord",
    },
    register: {
      "mobile.number": "Mobiel nummer",
      "email.address": "E-mailadres",
      password: {
        hash: "Wachtwoord",
        confirmation: "Bevestiging wachtwoord",
      },
      profile: {
        name: {
          first: "Voornaam",
          last: "Achternaam",
        },
      },
    },
    haveAccountLogin: "Heeft u al een account? Inloggen",
    notHaveAccountRegister: "Heeft u nog geen account? Registreren",
    forgot: "Wachtwoord vergeten",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Ordernummer",
      refundPolicy:
        "Binnen 20 minuten wordt het bedrag teruggestort op de balans als het nummer geen SMS ontvangt.",
      notEnoughCredits:
        "U heeft momenteel niet genoeg credits. U heeft {{amount}} meer credits nodig om dit nummer te gebruiken.",
      orderFor: "<0>Bestelling voor</0><1>{{amount}}</1><2>Credit</2>",
      pleaseWait: "Even geduld aub",
      addCredits: "Credits toevoegen",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Bestelling annuleren",
      cancel: "Annuleren",
      pleaseWait: "Even geduld aub",
      dismiss: "Sluiten",
    },
  },
};
