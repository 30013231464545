export default {
  title: "HidSim",
  description:
    "仮想電話番号でオンラインでSMSを受信し、匿名でサービスに登録する",
  og: {
    title: "SMS確認サービス",
    description:
      "仮想電話番号でオンラインでSMSを受信し、匿名でサービスに登録する",
    site_name: "HidSim",
    buyVirtualNumbersFor: "{{name}}のための仮想番号を購入する",
  },
  login: "ログイン",
  register: "登録",
  profile: "プロフィール",
  balance: "残高",
  topUp: "チャージ",
  language: "言語",
  currency: "通貨",
  support: "サポート",
  downloadIosApp: "iOSアプリをダウンロード",
  downloadAndroidApp: "Androidアプリをダウンロード",
  receipts: "領収書",
  invoices: "請求書",
  logout: "ログアウト",
  buyVirtualNumbersFor: "{{name}}のための仮想番号を購入する",
  connecting: "接続中",
  connected: "接続済み",
  retry: "再試行",
  credit: "クレジット",
  credits: "クレジット",
  services: "サービス",
  operations: "操作",
  pending: "保留中",
  refunded: "払い戻し済み",
  completed: "完了",
  searchServices: "サービスを検索",
  searchCountryFor: "{{name}}のための国を検索",
  default: "デフォルト",
  priceHighToLow: "価格の高い順",
  priceLowToHigh: "価格の低い順",
  nothingIsHereYet: "まだ何もありません",
  errors: {
    "emailAddressAlreadyTaken": "このメールアドレスは既に使用されています",
    "number.base": "数値を入力してください",
    "number.empty": "このフィールドは空にできません",
    "any.required": "このフィールドは必須です",
    "any.only": "入力された値は許可されていません",
    "string.base": "このフィールドを入力してください",
    "string.empty": "このフィールドは空にできません",
    "0": "インターネットに接続されていません",
    "401": "認証されていません",
    "401_message":
      "このセクションにアクセスする権限がありません。サイト管理者に連絡してください",
    "500": "接続エラー",
    "500_message":
      "インターネットへの接続に問題が発生しました。もう一度お試しください",
    "404": "見つかりません",
    "404_message":
      "お探しのページが見つかりませんでした。迷子ですか？ホームに戻るには以下のボタンをクリックしてください",
    "409": "トークンの有効期限切れ",
    "409_message":
      "最近のトークンの有効期限が切れています。ページを再読み込みして取引を続行してください",
    "429": "リクエストが多すぎます",
    "429_message":
      "許可されたリクエスト数を超えました。もう一度お試しください",
  },
  navbar: {
    services: "サービス",
    profile: "プロフィール",
    myNumbers: "マイ番号",
  },
  operation: {
    creating: "作成中",
    pending: "保留中",
    completed: "完了",
    no_number_refunded: "番号がないため払い戻し",
    system_refunded: "システムによる自動払い戻し",
    user_refunded: "ユーザーによる払い戻し",
  },
  auth: {
    login: {
      "mobile.number": "携帯番号",
      password: "パスワード",
      "email.address": "メールアドレス",
      invalid: "無効なメールアドレスまたはパスワード",
    },
    register: {
      "mobile.number": "携帯番号",
      "email.address": "メールアドレス",
      password: {
        hash: "パスワード",
        confirmation: "パスワードの確認",
      },
      profile: {
        name: {
          first: "名",
          last: "姓",
        },
      },
    },
    haveAccountLogin: "アカウントをお持ちですか？ログイン",
    notHaveAccountRegister: "アカウントをお持ちでないですか？登録",
    forgot: "パスワードをお忘れですか",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "注文番号",
      refundPolicy:
        "20分以内に番号がSMSを受信しない場合、コストは残高に返金されます。",
      notEnoughCredits:
        "現在、十分なクレジットがありません。この番号を使用するには{{amount}}クレジットがさらに必要です。",
      orderFor: "<0>注文先</0><1>{{amount}}</1><2>クレジット</2>",
      pleaseWait: "お待ちください",
      addCredits: "クレジットを追加",
    },
    cancelOrderConfirmation:{
      cancelOrder: "注文をキャンセル",
      cancel: "キャンセル",
      pleaseWait: "お待ちください",
      dismiss: "閉じる",
    },
  },
};
