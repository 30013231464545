export default {
  title: "HidSim",
  description:
    "รับ SMS ออนไลน์ด้วยหมายเลขโทรศัพท์เสมือนและลงทะเบียนโดยไม่เปิดเผยตัวตนในบริการใด ๆ",
  og: {
    title: "บริการยืนยันตัวตนด้วย SMS",
    description:
      "รับ SMS ออนไลน์ด้วยหมายเลขโทรศัพท์เสมือนและลงทะเบียนโดยไม่เปิดเผยตัวตนในบริการใด ๆ",
    site_name: "HidSim",
    buyVirtualNumbersFor: "ซื้อหมายเลขเสมือนสำหรับ {{name}}",
  },
  login: "เข้าสู่ระบบ",
  register: "ลงทะเบียน",
  profile: "โปรไฟล์",
  balance: "ยอดเงินคงเหลือ",
  topUp: "เติมเงิน",
  language: "ภาษา",
  currency: "สกุลเงิน",
  support: "สนับสนุน",
  downloadIosApp: "ดาวน์โหลดแอป iOS",
  downloadAndroidApp: "ดาวน์โหลดแอป Android",
  receipts: "ใบเสร็จรับเงิน",
  invoices: "ใบแจ้งหนี้",
  logout: "ออกจากระบบ",
  buyVirtualNumbersFor: "ซื้อหมายเลขเสมือนสำหรับ {{name}}",
  connecting: "กำลังเชื่อมต่อ",
  connected: "เชื่อมต่อแล้ว",
  retry: "ลองใหม่",
  credit: "เครดิต",
  credits: "เครดิต",
  services: "บริการ",
  operations: "การดำเนินการ",
  pending: "รอดำเนินการ",
  refunded: "คืนเงินแล้ว",
  completed: "เสร็จสิ้น",
  searchServices: "ค้นหาบริการ",
  searchCountryFor: "ค้นหาประเทศสำหรับ {{name}}",
  default: "ค่าเริ่มต้น",
  priceHighToLow: "ราคาสูงไปต่ำ",
  priceLowToHigh: "ราคาต่ำไปสูง",
  nothingIsHereYet: "ยังไม่มีอะไรที่นี่",
  errors: {
    "emailAddressAlreadyTaken": "ที่อยู่อีเมลถูกใช้งานแล้ว",
    "number.base": "โปรดป้อนตัวเลข",
    "number.empty": "ไม่สามารถเว้นว่างฟิลด์นี้ได้",
    "any.required": "จำเป็นต้องกรอกฟิลด์นี้",
    "any.only": "ค่าที่ป้อนไม่ได้รับอนุญาต",
    "string.base": "โปรดกรอกฟิลด์นี้",
    "string.empty": "ไม่สามารถเว้นว่างฟิลด์นี้ได้",
    "0": "ไม่มีการเชื่อมต่ออินเทอร์เน็ต",
    "401": "ไม่ได้รับอนุญาต",
    "401_message":
      "คุณไม่ได้รับอนุญาตให้เข้าถึงส่วนนี้ โปรดติดต่อผู้ดูแลเว็บไซต์",
    "500": "ข้อผิดพลาดในการเชื่อมต่อ",
    "500_message":
      "มีปัญหาในการเชื่อมต่อกับอินเทอร์เน็ต โปรดลองอีกครั้ง",
    "404": "ไม่พบ",
    "404_message":
      "เราไม่พบสิ่งที่คุณกำลังมองหา คุณหลงทางหรือเปล่า? คลิกปุ่มด้านล่างเพื่อกลับสู่หน้าหลัก",
    "409": "โทเค็นหมดอายุ",
    "409_message":
      "โทเค็นล่าสุดของคุณหมดอายุแล้ว โปรดโหลดหน้าเว็บใหม่และดำเนินการทำธุรกรรมต่อไป",
    "429": "คำขอมากเกินไป",
    "429_message":
      "คุณเกินจำนวนคำขอที่อนุญาต โปรดลองอีกครั้ง",
  },
  navbar: {
    services: "บริการ",
    profile: "โปรไฟล์",
    myNumbers: "หมายเลขของฉัน",
  },
  operation: {
    creating: "กำลังสร้าง",
    pending: "รอดำเนินการ",
    completed: "เสร็จสิ้น",
    no_number_refunded: "คืนเงินเนื่องจากไม่มีหมายเลข",
    system_refunded: "ระบบคืนเงินโดยอัตโนมัติ",
    user_refunded: "คืนเงินโดยคุณ",
  },
  auth: {
    login: {
      "mobile.number": "หมายเลขโทรศัพท์มือถือ",
      password: "รหัสผ่าน",
      "email.address": "ที่อยู่อีเมล",
      invalid: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
    },
    register: {
      "mobile.number": "หมายเลขโทรศัพท์มือถือ",
      "email.address": "ที่อยู่อีเมล",
      password: {
        hash: "รหัสผ่าน",
        confirmation: "ยืนยันรหัสผ่าน",
      },
      profile: {
        name: {
          first: "ชื่อ",
          last: "นามสกุล",
        },
      },
    },
    haveAccountLogin: "มีบัญชีอยู่แล้ว? เข้าสู่ระบบ",
    notHaveAccountRegister: "ยังไม่มีบัญชี? ลงทะเบียน",
    forgot: "ลืมรหัสผ่าน",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "หมายเลขคำสั่งซื้อ",
      refundPolicy:
        "ภายใน 20 นาทีหากหมายเลขไม่ได้รับ SMS ค่าใช้จ่ายจะถูกคืนเข้าสู่ยอดเงินคงเหลือ",
      notEnoughCredits:
        "คุณไม่มีเครดิตเพียงพอในขณะนี้ คุณต้องการเพิ่มเครดิตอีก {{amount}} เพื่อใช้หมายเลขนี้",
      orderFor: "<0>คำสั่งซื้อสำหรับ</0><1>{{amount}}</1><2>เครดิต</2>",
      pleaseWait: "โปรดรอ",
      addCredits: "เพิ่มเครดิต",
    },
    cancelOrderConfirmation:{
      cancelOrder: "ยกเลิกคำสั่งซื้อ",
      cancel: "ยกเลิก",
      pleaseWait: "โปรดรอ",
      dismiss: "ปิด",
    },
  },
};
