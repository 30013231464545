export default {
  title: "HidSim",
  description:
    "Receba SMS online com números de telefone virtuais e registre-se anonimamente em qualquer serviço",
  og: {
    title: "Serviço de Verificação de SMS",
    description:
      "Receba SMS online com números de telefone virtuais e registre-se anonimamente em qualquer serviço",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Comprar Números Virtuais Para {{name}}",
  },
  login: "Entrar",
  register: "Registrar",
  profile: "Perfil",
  balance: "Saldo",
  topUp: "Recarregar",
  language: "Idioma",
  currency: "Moeda",
  support: "Suporte",
  downloadIosApp: "Baixar App para iOS",
  downloadAndroidApp: "Baixar App para Android",
  receipts: "Recibos",
  invoices: "Faturas",
  logout: "Sair",
  buyVirtualNumbersFor: "Comprar Números Virtuais Para {{name}}",
  connecting: "Conectando",
  connected: "Conectado",
  retry: "Tentar Novamente",
  credit: "Crédito",
  credits: "Créditos",
  services: "Serviços",
  operations: "Operações",
  pending: "Pendente",
  refunded: "Reembolsado",
  completed: "Concluído",
  searchServices: "Buscar Serviços",
  searchCountryFor: "Buscar País Para {{name}}",
  default: "Padrão",
  priceHighToLow: "Preço Alto para Baixo",
  priceLowToHigh: "Preço Baixo para Alto",
  nothingIsHereYet: "Ainda não há nada aqui",
  errors: {
    "emailAddressAlreadyTaken": "Endereço de e-mail já utilizado",
    "number.base": "Por favor, insira um número",
    "number.empty": "Este campo não pode ficar vazio",
    "any.required": "Este campo é obrigatório",
    "any.only": "O valor inserido não é permitido",
    "string.base": "Por favor, preencha o campo",
    "string.empty": "Este campo não pode ficar vazio",
    "0": "Sem conexão com a internet",
    "401": "Não autorizado",
    "401_message":
      "Você não está autorizado a acessar esta seção, por favor, entre em contato com o administrador do site",
    "500": "Erro de conexão",
    "500_message":
      "Houve um problema ao conectar-se à internet, por favor, tente novamente",
    "404": "Não encontrado",
    "404_message":
      "Não conseguimos encontrar o que você estava procurando, você está perdido? Clique no botão abaixo para voltar à página inicial",
    "409": "Token expirado",
    "409_message":
      "Seu token recente expirou, por favor, recarregue a página e prossiga com a transação",
    "429": "Muitas solicitações",
    "429_message":
      "Você excedeu o número permitido de solicitações, por favor, tente novamente",
  },
  navbar: {
    services: "Serviços",
    profile: "Perfil",
    myNumbers: "Meus Números",
  },
  operation: {
    creating: "Criando",
    pending: "Pendente",
    completed: "Concluído",
    no_number_refunded: "Reembolsado devido à falta de número",
    system_refunded: "Reembolsado automaticamente pelo sistema",
    user_refunded: "Reembolsado por você",
  },
  auth: {
    login: {
      "mobile.number": "Número de Celular",
      password: "Senha",
      "email.address": "Endereço de E-mail",
      invalid: "E-mail ou senha inválidos",
    },
    register: {
      "mobile.number": "Número de Celular",
      "email.address": "Endereço de E-mail",
      password: {
        hash: "Senha",
        confirmation: "Confirmação de Senha",
      },
      profile: {
        name: {
          first: "Primeiro Nome",
          last: "Último Nome",
        },
      },
    },
    haveAccountLogin: "Já tem uma conta? Entrar",
    notHaveAccountRegister: "Não tem uma conta? Registrar",
    forgot: "Esqueceu a senha",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Número do Pedido",
      refundPolicy:
        "Dentro de 20 minutos, se o número não receber SMS, o custo será devolvido ao saldo.",
      notEnoughCredits:
        "Você não possui créditos suficientes no momento. Você precisa de mais {{amount}} créditos para usar este número.",
      orderFor: "<0>Pedido para</0><1>{{amount}}</1><2>Crédito</2>",
      pleaseWait: "Por favor, aguarde",
      addCredits: "Adicionar Créditos",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Cancelar Pedido",
      cancel: "Cancelar",
      pleaseWait: "Por favor, aguarde",
      dismiss: "Fechar",
    },
  },
};
