export default {
  title: "HidSim",
  description:
    "Получайте SMS онлайн с виртуальными номерами телефонов и регистрируйтесь анонимно в любой службе",
  og: {
    title: "Сервис проверки SMS",
    description:
      "Получайте SMS онлайн с виртуальными номерами телефонов и регистрируйтесь анонимно в любой службе",
    site_name: "HidSim",
    buyVirtualNumbersFor: "Купить виртуальные номера для {{name}}",
  },
  login: "Войти",
  register: "Зарегистрироваться",
  profile: "Профиль",
  balance: "Баланс",
  topUp: "Пополнить",
  language: "Язык",
  currency: "Валюта",
  support: "Поддержка",
  downloadIosApp: "Скачать iOS приложение",
  downloadAndroidApp: "Скачать Android приложение",
  receipts: "Квитанции",
  invoices: "Счета",
  logout: "Выйти",
  buyVirtualNumbersFor: "Купить виртуальные номера для {{name}}",
  connecting: "Подключение",
  connected: "Подключено",
  retry: "Повторить",
  credit: "Кредит",
  credits: "Кредиты",
  services: "Сервисы",
  operations: "Операции",
  pending: "Ожидание",
  refunded: "Возвращено",
  completed: "Завершено",
  searchServices: "Поиск сервисов",
  searchCountryFor: "Поиск страны для {{name}}",
  default: "По умолчанию",
  priceHighToLow: "Цена от высокой к низкой",
  priceLowToHigh: "Цена от низкой к высокой",
  nothingIsHereYet: "Здесь пока ничего нет",
  errors: {
    "emailAddressAlreadyTaken": "Адрес электронной почты уже занят",
    "number.base": "Пожалуйста, введите число",
    "number.empty": "Это поле не может быть пустым",
    "any.required": "Это поле обязательно для заполнения",
    "any.only": "Введенное значение не разрешено",
    "string.base": "Пожалуйста, заполните поле",
    "string.empty": "Это поле не может быть пустым",
    "0": "Нет подключения к интернету",
    "401": "Неавторизованный",
    "401_message":
      "У вас нет разрешения на доступ к этому разделу, пожалуйста, свяжитесь с администратором сайта",
    "500": "Ошибка подключения",
    "500_message":
      "Возникла проблема с подключением к интернету, пожалуйста, попробуйте еще раз",
    "404": "Не найдено",
    "404_message":
      "Мы не смогли найти то, что вы искали, вы заблудились? Нажмите кнопку ниже, чтобы перейти на главную страницу",
    "409": "Истекший токен",
    "409_message":
      "Ваш недавний токен истек, пожалуйста, перезагрузите страницу и продолжите транзакцию",
    "429": "Слишком много запросов",
    "429_message":
      "Вы превысили разрешенное количество запросов, пожалуйста, попробуйте еще раз",
  },
  navbar: {
    services: "Сервисы",
    profile: "Профиль",
    myNumbers: "Мои номера",
  },
  operation: {
    creating: "Создание",
    pending: "Ожидание",
    completed: "Завершено",
    no_number_refunded: "Возвращено из-за отсутствия номера",
    system_refunded: "Система автоматически вернула средства",
    user_refunded: "Возвращено вами",
  },
  auth: {
    login: {
      "mobile.number": "Мобильный номер",
      password: "Пароль",
      "email.address": "Адрес электронной почты",
      invalid: "Неверный адрес электронной почты или пароль",
    },
    register: {
      "mobile.number": "Мобильный номер",
      "email.address": "Адрес электронной почты",
      password: {
        hash: "Пароль",
        confirmation: "Подтверждение пароля",
      },
      profile: {
        name: {
          first: "Имя",
          last: "Фамилия",
        },
      },
    },
    haveAccountLogin: "Уже есть аккаунт? Войти",
    notHaveAccountRegister: "Нет аккаунта? Зарегистрироваться",
    forgot: "Забыли пароль",
  },
  landing: {
    nav: {},
  },
  modals: {
    orderConfirmation: {
      orderNumber: "Номер заказа",
      refundPolicy:
        "В течение 20 минут, если номер не получает SMS, стоимость будет возвращена на баланс.",
      notEnoughCredits:
        "У вас недостаточно кредитов. Вам нужно еще {{amount}} кредитов, чтобы использовать этот номер.",
      orderFor: "<0>Заказ для</0><1>{{amount}}</1><2>Кредит</2>",
      pleaseWait: "Пожалуйста, подождите",
      addCredits: "Пополнить кредиты",
    },
    cancelOrderConfirmation:{
      cancelOrder: "Отменить заказ",
      cancel: "Отменить",
      pleaseWait: "Пожалуйста, подождите",
      dismiss: "Отмена",
    },
  },
};
